/**
 * Created by petermoller on 02/09/16.
 */
"use strict";
let React = require('react');
var createReactClass = require('create-react-class');

var Avatar = createReactClass({

    getDefaultProps: function () {
        return {};
    },

    getSizeCode(size) {
        let sizeIsNumber = !isNaN(size);
        if (sizeIsNumber) {
            if (size < 50) {
                return "small";
            } else if (size < 101) {
                return "medium";
            }
            return "large";
        }
        return size;
    },

    getAvatarUrl(sizeCode) {
        //console.log("getAvatarUrl", sizeCode, this.props);
        try {
            return this.props.member.avatar[sizeCode];
        } catch (e) {
            return null;
        }
    },

    getSizePx(size) {
        switch (size) {
            case 'xsmall':
                return 35;
            case 'small':
                return 50;
            case 'medium':
                return 100;
            case 'large':
                return 200;
            default:
                return size;
        }
    },

    render() {
        //console.log("render Avatar", this.props, this.props.member.initials);
        let sizeCode = this.getSizeCode(this.props.size);
        let sizePx = this.getSizePx(this.props.size);
        let avatarUrl = this.getAvatarUrl(sizeCode);
        let className = "c-avatar c-avatar--" + sizeCode;
        if (!avatarUrl) {
            className += " c-avatar--no-img";
            return (
                <div className={className} data-initials={ this.props.member.initials }>
                </div>
            );
        }

        return (
            <div className={className}>
                <img src={avatarUrl} alt={this.props.member.full_name} width={sizePx} height={sizePx}/>
            </div>
        );
    }
});

module.exports = Avatar;