/**
* Created by petermoeller on 28/10/15.
*/
"use strict"
var React = require('react');
var moment = require('moment-timezone');
var createReactClass = require('create-react-class');
var PropTypes = require('prop-types');

var Clock = createReactClass({
  getInitialState: function () {
    if (this.props.tz) {
      var tz = this.props.tz;
      var now = moment().tz(this.props.tz);
    } else {
      var tz = null;
      var now = moment();
    }
    var tFormat = (this.props.tFormat) ? this.props.tFormat : 'HH:mm';
    return {now:now, tz: tz, intervalId: null, tFormat: tFormat, locale:this.props.locale};
  },

  refreshTime: function () {
    if (this.state.tz) {
      var now = moment().tz(this.state.tz);
    } else {
      var now = moment()
    }

    this.setState({now: now})
  },

  componentDidMount: function() {
    // load shifts from server and setInterval for doing this later on.
    // TODO: move setInterval stuff into a combined websockets/interval-set-clear method.
    this.refreshTime();
    var intervalId = setInterval(this.refreshTime, 5000);
    this.setState({intervalId: intervalId});
  },

  componentWillReceiveProps(nextProps) {
    this.setState({
      tFormat: nextProps.tFormat,
      tz: nextProps.tz,
      locale: nextProps.locale
    });
  },

  componentWillUnmount: function() {
    // clearInterval when unmounted... no more need for fetching shifts.
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  },

  render: function () {
    // console.log("Clock render", this.props);
    var time = this.state.now.format(this.state.tFormat);
	return <div>{time}</div>
  }
});


module.exports = Clock;