/**
 * Created by petermoeller on 28/10/15.
 */
"use strict"

var React = require('react');
var ReactDOM = require('react-dom');
var createReactClass = require('create-react-class');
var PropTypes = require('prop-types');
// Remove on React 1.0
//var injectTapEventPlugin = require("react-tap-event-plugin");
//injectTapEventPlugin();

// REACT Components...
var KeyPadDisplay = createReactClass({
  getInitialState: function(){
    // this.props er initial data sendt med fra 'parent'...
    return {input: [], max_input: this.props.max_input};
  },
  componentDidMount: function(){
  },
  componentWillReceiveProps: function(nextProps) {
      this.setState(nextProps);
  },
  // check status and render accordingly
  render: function() {
    var chars = [];
    for (var i = 0; i < this.state.max_input; i++) {
      var text = this.state.input[i];
      if (text) {
        chars.push(<div className="pinInput" key={i}><span className="pinInputFilled"></span></div>);
      } else {
        chars.push(<div className="pinInput" key={i}></div>);
      }
    }
    return (
      <div className="pinCon">
        {chars}
      </div>
    )
  }
});


var KeyPadButton = createReactClass({
  getInitialState: function () {
    return {
      key: this.props.data,
      isClicked: false // Track if the button is in clicked state
    };
  },
  componentDidMount: function () {
    const domNode = ReactDOM.findDOMNode(this);
    // Add non-passive event listeners for both touch and mouse events
    domNode.addEventListener('touchstart', this.handleMouseDown, { passive: false });
    domNode.addEventListener('touchend', this.handleMouseUp, { passive: false });
    domNode.addEventListener('mousedown', this.handleMouseDown, { passive: false });
    domNode.addEventListener('mouseup', this.handleMouseUp, { passive: false });
  },

  componentWillUnmount: function () {
    const domNode = ReactDOM.findDOMNode(this);
    // Remove non-passive event listeners for both touch and mouse events
    domNode.removeEventListener('touchstart', this.handleMouseDown, { passive: false });
    domNode.removeEventListener('touchend', this.handleMouseUp, { passive: false });
    domNode.removeEventListener('mousedown', this.handleMouseDown, { passive: false });
    domNode.removeEventListener('mouseup', this.handleMouseUp, { passive: false });
  },

  handleMouseDown: function (e) {
    e.preventDefault();
    this.setState({ isClicked: true });
  },

  handleMouseUp: function (e) {
    e.preventDefault();
    this.setState({ isClicked: false });
    this.props.clicked(this.state.key);
  },
  toggleClickedState: function (isClicked) {
    this.setState({ isClicked });
  },

  // check status and render accordingly
  render: function () {
    var button_text = this.state.key;
    if (button_text === 'clear') {
      button_text = gettext("ryd");
    }
    else if (button_text === 'delete') {
      button_text = gettext("slet");
    }

    // Apply 'clicked' class based on the state
    var btnClass = "keyBtn" + (this.state.isClicked ? " clicked" : "");

    return (
      <div className={btnClass} tabIndex="-1">
        <span className="btnContent">
          <span className="btnText">
            {button_text}
          </span>
        </span>
      </div>
    )
  }
});




var KeyPad = createReactClass({
  getInitialState: function(){
    // this.props er initial data sendt med fra 'parent'...
    return {input: [], submitOnChars: this.props.submitOnChars, keys: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "delete", "0", "clear"]};
  },
  componentDidMount: function () {
    // Add non-passive event listener for keydown
    var dom_node = ReactDOM.findDOMNode(this);
    dom_node.offsetParent.addEventListener('keydown', this.keyPressListener, { passive: false });
  },

  componentWillUnmount: function () {
    // Remove non-passive event listener for keydown
    var dom_node = ReactDOM.findDOMNode(this);
    dom_node.offsetParent.removeEventListener('keydown', this.keyPressListener, { passive: false });
  },
  keyPressListener: function(e){
    // keyPressListener has to be named... otherwise the listener can't be removed later on.
    this.handleKeyPress(e);
  },
  componentDidMount: function(){
    // add eventlistener for keypresses
    var dom_node = ReactDOM.findDOMNode(this);
    dom_node.offsetParent.addEventListener('keydown', this.keyPressListener);
  },
  componentWillUnmount: function () {
    // remove eventlistener for keypresses!
    var dom_node = ReactDOM.findDOMNode(this);
    dom_node.offsetParent.removeEventListener('keydown', this.keyPressListener ,false);
  },
  // In KeyPad component
  handleKeyPress(e) {
    var keyCode = e.keyCode;
    var value = String.fromCharCode(keyCode);
    if (["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"].includes(value)) {
      this.buttonClick(value);
      this.toggleButtonClickedState(value, true);
      setTimeout(() => this.toggleButtonClickedState(value, false), 100); // Adjust time as needed
    } else if (keyCode === 8 && this.state.input.length > 0) {
      e.preventDefault();
      this.buttonClick('delete');
      this.toggleButtonClickedState('delete', true);
      setTimeout(() => this.toggleButtonClickedState('delete', false), 100); // Adjust time as needed
    }
  },

  toggleButtonClickedState: function (key, isClicked) {
    if (this.refs[key]) {
      this.refs[key].toggleClickedState(isClicked);
    }
  },

  

  componentWillReceiveProps: function(nextProps) {
    this.setState(nextProps);
  },
  buttonClick: function (key) {
    // TODO: don't catch button clicks on pages other than login...!
    var input = this.state.input;
    if (key === 'clear'){
      input = [];
    }
    else if (key === 'delete') {
      //this.props.onCancel();
      input.pop();
    }
    else {
      input.push(key);
    }
    this.setState({input: input});

    if (this.state.input.length.toString() === this.state.submitOnChars){
      this.props.onSubmit(this.state.input);
      this.setState({input: []});
    }
  },

  // check status and render accordingly
  render: function() {
    
    // In the render method of KeyPad
    var clickHandler = this.buttonClick;
    var keys = this.state.keys.map(function (key) {
      return (
        <KeyPadButton clicked={clickHandler} data={key} key={key} ref={key} />
      );
    });
    return (
      <div>
        <div className="keypad-container">
          { this.props.showHeader && 
          <div>
            <h1>{gettext('Indtast 6-cifret pinkode for at lukke stempeluret.')}</h1>
          </div>
          }
          <div className="keypad-content">
            <KeyPadDisplay input={this.state.input} max_input={this.state.submitOnChars} />
            <div className="keypad">
              {keys}
            </div>
          </div>
        </div>
      </div>
    )
  }
});


module.exports = {
  Main: KeyPad
};
