/**
 * Created by petermoeller on 28/10/15.
 */
"use strict";
var React = require('react');
var ReactDOM = require('react-dom');
var PunchclockApp = require('../../react_components/punchclock/app');


document.addEventListener('DOMContentLoaded', function() {

    var punchclockElem = document.getElementById('punchclock');

    if (!!punchclockElem) {
        ReactDOM.render(
          <PunchclockApp.App />,
          document.getElementById('punchclock')
        );
    }

});