/**
 * Created by petermoeller on 28/10/15.
 */
"use strict";
// Remove on React 1.0
var React = require('react');
var PunchClock = require('./punchclock');
var Terminal = require('./terminal');
var Api = require('../common/api');
var createReactClass = require('create-react-class');
var PropTypes = require('prop-types');
var injectTapEventPlugin = require("react-tap-event-plugin");
injectTapEventPlugin();

var App = createReactClass({
  childContextTypes: {
      appLoadTerminal: PropTypes.func,
      appTerminalLogOut: PropTypes.func,
      appAccountToken: PropTypes.string,
      appAccountLocationUUID: PropTypes.string,
      appAccountLocationTitle: PropTypes.string,
      appRaiseInvalidAccountToken: PropTypes.func,
      appSettings: PropTypes.object
  },
  getChildContext: function() {
    var context = {
      appLoadTerminal: this.loadTerminal,
      appTerminalLogOut: this.clearAccountSession,
      appAccountToken: this.state.accountToken,
      appAccountLocationUUID: this.state.locationUUID,
      appAccountLocationTitle: this.state.locationTitle,
      appRaiseInvalidAccountToken: this.handleInvalidAccountToken,
      appSettings: this.state.settings
    };
    return context;
  },

  localStorageSupport: function() {
    var mod = 'modernizr';
    try {
        localStorage.setItem(mod, mod);
        localStorage.removeItem(mod);
        return true;
    } catch(e) {
        return false;
    }
  },
  getInitialState: function() {
    // TODO: Guess initial timezone...
    // get Account UUID
    var accountUUID = $("#punchclock").data("accountuuid");
    var locationUUID = $("#punchclock").data("locationuuid");
    var locationTitle = $("#punchclock").data("locationtitle");
    const languageId = $("#punchclock").data("languageid");

    var initialState = {
        accountUUID: accountUUID,
        locationUUID: locationUUID,
        locationTitle: locationTitle,
        accountToken: null,
        selectedEmployee: "",
        languageId: languageId,
        errMsg: "",
        confirmation: null,
        settings: {
          tz: "Europe/Copenhagen",
          breakSupport: 'off',
          momentLang: 'en',
          clock_format: '24',
          user_time_format: 'HH:mm'
        },
        intervalID: null
    };

    // Check if localStorage is supported!
    var localStorageSupport = this.localStorageSupport();
    if (!localStorageSupport) {
      initialState["errMsg"] = gettext("Cookies er blokeret el. din browser er sat til private-browsing. Ret venligst dette og prøv igen.");
      return initialState;
    }

    // Get accountToken from localStorage
    var accountToken = window.localStorage.getItem('accountToken') || "";
    return {
      accountUUID: accountUUID,
      locationUUID: locationUUID,
      locationTitle: locationTitle,
      accountToken: accountToken,
      selectedEmployee: "",
      languageId: languageId,
      errMsg: "",
      confirmation: null,
      settings: {
        tz: "Europe/Copenhagen",
        breakSupport: 'off',
        momentLang: 'en',
        clock_format: '24',
        user_time_format: 'HH:mm'
      },
      intervalID: null
    };
  },

  // FETCH SETTINGS
  loadSettingsFromServer: function (block) {
    var apiDispatchCallback = function(jqXHR) {
      if (jqXHR.statusCode === 200)  {
        var settings = jqXHR.body.settings,
            user_time_format;

        user_time_format = (settings.clock_format == 12) ? "LT" : "HH:mm";
        settings.user_time_format = user_time_format;

        this.setState({settings: settings});

      }
      else if ([401].indexOf(jqXHR.statusCode) >= 0) {
        this.handleInvalidAccountToken({msg: gettext("Ugyldig pinkode, prøv venligst igen.")});
      }
      else {
        var errMsg = jqXHR.body.detail ? jqXHR.body.detail : gettext("Der skete en fejl. Prøv venligst igen.");
        this.setState({errMsg: errMsg});
      }
    }.bind(this);

    if (this.state.accountToken) {
      Api.Dispatcher({
        endPoint: 'punchclock/settings/',
        method: 'get',
        headers: {'Authorization': "token " + this.state.accountToken}
      }, apiDispatchCallback)
    }
  },

  componentDidMount: function() {
    // load shifts from server and setInterval for doing this later on.
    // TODO: move setInterval stuff into a combined websockets/interval-set-clear method.
    this.loadSettingsFromServer();
    var intervalId = setInterval(this.loadSettingsFromServer, 30000);
    this.setState({intervalId: intervalId});
  },

  componentWillUnmount: function() {
    // clearInterval when unmounted... no more need for fetching shifts.
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  },

  // CALLBACKS
  handleEmployeeSelection: function(selection){
    var selected_employee = "";
    if (selection) {
      selected_employee = selection;
    }
    this.setState({selectedEmployee: selected_employee});


    // >>> should show the PunchClockLogin Component
  },

  handleUserToken: function(value){
    var user_token = "";
    if (value) {
      user_token = value;
    }
    this.setState({userToken: user_token});
    // >>> should show the PunchClock Component
  },
  handleAccountToken: function(token){
    // when the DashboardLogin Component has received a new account_token this method is run and the token is saved.
    var account_token = "";
    if (token) {
      account_token = token;
    }
    var localStorageSupport = this.localStorageSupport();
    if (!localStorageSupport) {
      var errMsg = gettext("Cookies er blokeret el. din browser er sat til private-browsing. Ret venligst dette og prøv igen.");
      this.setState({errMsg: errMsg});
    } else {
      window.localStorage.setItem('accountToken', account_token);
      this.setState({accountToken: account_token, errMsg: ""});
      this.loadSettingsFromServer()
      // >>> should show the Dashboard Component
    }
  },

  // ERROR STUFF
  handleInvalidAccountToken: function(kwargs){
      var args = {};
      args.msg = kwargs.msg || gettext("Der skete en fejl. Log venligst ind igen.");
      window.localStorage.clear();
      if (!this.state.errMsg) {
        this.setState({selectedEmployee: "", accountToken: "", errMsg: args.msg});
      }
  },
  cancelEmployeeSelection: function (){
    this.setState({selectedEmployee: ""});
    this.forceUpdate();
    // >>> should show the Dashboard Component Again...
  },
  clearUserSession: function () {
    // removes user_token from the current user on the punch clock.
    this.setState({selectedEmployee: ""});
    // >>> should show the Dashboard Component Again...
  },
  clearAccountSession: function () {
    // removes accountToken from localStorage and App.
    window.localStorage.clear();
    this.setState({selectedEmployee: "", accountToken: "", confirmation: null});
    // >>> should show the TerminalLogin page.
  },
  loadTerminal: function (kwargs) {
    // TODO: default for kwargs...!
    // clear userToken and selectedEmployee... and forcing an update of the App component.
    // Checks for 'confirmation' msg ind kwargs. If its there a confirmation window will appear with the message.
    var args = {};
    var kwargs = kwargs ? kwargs : {};
    args.confirmation = kwargs.confirmation || null;
    this.setState({confirmation: args.confirmation, selectedEmployee: ""});
  },

  // RENDERERS
  renderMainContent: function () {
    var confirmation = this.state.confirmation;
    var account_token = this.state.accountToken;
    var selected_employee = this.state.selectedEmployee;
    if (account_token){
        if (selected_employee){
          return (
            <PunchClock.Main employee={this.state.selectedEmployee}/>
          );
        }
        else {
          return (
            <Terminal.Terminal onEmployeeSelected={this.handleEmployeeSelection} confirmation={confirmation} accountUUID={this.state.accountUUID} locationUUID={this.state.locationUUID} languageId={this.state.languageId} />
          );
        }
    } else {
      return (
        <Terminal.Login onTokenReceived={this.handleAccountToken} accountUUID={this.state.accountUUID} locationUUID={this.state.locationUUID} languageId={this.state.languageId}/>
      );
    }
  },

  renderErrorModal: function () {
    // TODO: render this.state.errMsg! Gerne i en modal!
    // renders the errMsg in an alert box if the App is mounted.

    if (this.state.errMsg) {
      return (
        alert(this.state.errMsg)
      );
    }
    return;
  },


  renderConfirmationModal: function () {
    return;
  },

  render: function() {
    var appWindow = this.renderMainContent();
    var alertModal = this.renderErrorModal();
    var confirmationModal = null; //this.renderConfirmationModal();
    return (
      <div className="height-100">
        {alertModal}
        {confirmationModal}
        <div className="height-100">
          {appWindow}
        </div>
      </div>
    );

  }
});

module.exports = {
    App: App
};