/**
 * Created by petermoeller on 29/09/15.
 */
"use strict"
var options = require('./options');
var request = require('superagent');
var Cookie = require('js-cookie');

var ApiDispatcher= function(kwargs, callback) {
  var endPoint = kwargs.endPoint || null;
  var method = kwargs.method || 'get';
  var data = kwargs.data || '';
  var headers = kwargs.headers || {};
  var datatype = kwargs.datatype || 'json';
  if (options.dev) {
    var apiURL = "http://api.localhost:8000/v1/" + endPoint;
  } else {
    var apiURL = "https://api.smartplanapp.io/v1/" + endPoint;
  }
  request(method, apiURL).set(headers).accept(datatype).send(data).end(function(err, res){
     if (!res){
       // TODO: error? log to opbeat...
     } else {
       if (!res.body) {
         res.body = res.body ? res.body : {detail: null};
       }
       callback(res);
     }
   });
};


var CSRFDispatcher = function(kwargs, callback) {
  var endPoint = kwargs.endPoint || null;
  var method = kwargs.method || 'get';
  var data = kwargs.data || '';
  var query = kwargs.query || {};
  var headers = kwargs.headers || {};
  var datatype = kwargs.datatype || 'json';

  var apiURL = "/api/v1/" + endPoint;
  if (endPoint.indexOf("/api/v1/") > -1) {
    apiURL = endPoint;
  }

  headers['X-CSRFToken'] = Cookie.get('csrftoken');

  var req = request(method, apiURL).withCredentials().query(query).set(headers).accept(datatype).send(data).end(function(err, res){
     if (res == null){
       // TODO: error? log to opbeat...
     } else {
       if (res.body == null) {
         res.body = res.body ? res.body : {detail: null};
       } else {
         res.body.detail = res.body.detail ? res.body.detail : null;
       }
       callback(res);
     }
   });
  return req;
};


var CheckConnection = function(callback) {
  // Proof of concept for a HEAD request to check for a connection... to be used eventually.
  var apiURL = null;
  if (options.dev) {
    apiURL = "http://api.localhost:8000/v1/";
  } else {
    apiURL = "https://api.smartplanapp.io/v1/";
  }
  //console.log("api url:", apiURL);
  var ajaxcall = $.ajax({
    url: apiURL + "punchclock/check/",
    dataType: "json",
    type: "head"
  });

  ajaxcall.complete(function(jqXHR) {
    console.log("complete... ", jqXHR.status);
    if (200 <= jqXHR.status < 400) {
      console.log("works!");
    }
    else {
      console.log("no connection");
      callback();
    }
  });

};


module.exports = {
  ApiDispatcher: ApiDispatcher,
  CSRFDispatcher: CSRFDispatcher,
  CheckConnection: CheckConnection,
  Dispatcher: ApiDispatcher
};
