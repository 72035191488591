/**
 * Created by petermoeller on 28/10/15.
 *
 *
 *
 */
"use strict"
var React = require('react');
var ReactDOM = require('react-dom');
var KeyPad = require('./keypad');
var Api = require('../common/api');
var moment = require('moment-timezone');
var moment_js = require('moment/min/moment-with-locales');
var Clock = require('./clock');
var SuccessBox = require('./successbox');
var createReactClass = require('create-react-class');
var PropTypes = require('prop-types');
var Avatar = require('../common/avatar');  // When using new style classes


var EventListContainer = createReactClass({
  contextTypes: {
      // Get context from App component. Timezone for the account
      appAccountToken:  PropTypes.string,
      appRaiseInvalidAccountToken: PropTypes.func,
      appAccountLocationUUID: PropTypes.string,
      appAccountLocationTitle: PropTypes.string
  },
  getInitialState: function() {
    var events = [];
    if (localStorage.eventListContainerState) {
      var cachedEventListContainerState = JSON.parse(localStorage.eventListContainerState);
      if (!!cachedEventListContainerState && cachedEventListContainerState.hasOwnProperty('events')) {
        events = cachedEventListContainerState.events;
        localStorage.eventListContainerState = null;
      }
    }
    return {events: events, intervalId: null, lastModified: null};
  },
  loadDataFromServer: function () {
    // GET request callback
    var apiGetCallback = function(jqXHR) {
      if (jqXHR.statusCode === 200)  {
        var events = jqXHR.body.events;
          this.setState({events: events});

      }
      else if ([401].indexOf(jqXHR.statusCode) >= 0) {
        this.context.appRaiseInvalidAccountToken({msg: gettext("Ugyldig pinkode, prøv venligst igen.")});
      }
      else {
        var errMsg = jqXHR.body.detail ? jqXHR.body.detail : gettext("Der skete en fejl. Prøv venligst igen.");
      }
    }.bind(this);

    // HEAD request
    // Construct endpoint
    var endPoint = 'punchclock/dashboard/events/'
    if (!!this.context.appAccountLocationUUID) {
      endPoint = 'punchclock/location/' + this.context.appAccountLocationUUID + '/dashboard/events/'
    }
    var apiHeadCallback = function(jqXHR) {
      var getEvents = false;
      // TODO: change to superagent syntax!
      var lastModified = jqXHR.header['last-modified'] || null;

      if (this.state.lastModified){
        var prevLastModified = moment(this.state.lastModified, "YYYY-MM-DD HH:mm");
        var thisLastModified = moment(lastModified, "YYYY-MM-DD HH:mm");
        if (prevLastModified < thisLastModified) {
          getEvents = true;
        }
      } else {
        getEvents = true;
      }

      this.setState({lastModified: lastModified});
      if (getEvents){
        Api.Dispatcher({endPoint: endPoint, method: 'get', headers: {'Authorization': "token " + this.context.appAccountToken}}, apiGetCallback)
      }
    }.bind(this);
    Api.Dispatcher({endPoint: endPoint, method: 'head', headers: {'Authorization': "token " + this.context.appAccountToken}}, apiHeadCallback)
  },

  componentDidMount: function(){
    this.loadDataFromServer();
    var intervalId = setInterval(this.loadDataFromServer, 20000);
    this.setState({intervalId: intervalId});
  },

  componentWillUnmount: function() {
    // clearInterval when unmounted... no more need for fetching shifts.
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  },
  componentDidUpdate: function() {
    localStorage.eventListContainerState = JSON.stringify(this.state);
  },
  render() {
    return (
        <div  className="col-xs-12 col-sm-5 activity-wrap">
          <EventList events={this.state.events} selectEmployeeCallBack={this.props.selectEmployeeCallBack}/>
        </div>
    );
  }
});



var EventList = createReactClass({
  contextTypes: {
      // Get context from App component. Timezone for the account
      appSettings:  PropTypes.object,
      appTerminalLogOut: PropTypes.func,
      appAccountLocationTitle: PropTypes.string
  },

  hideActivity(){
    $(".activity-wrap").removeClass("open");
  },

  render: function() {
    var current_tz = moment().tz(this.context.appSettings.tz);
    var date_now = moment_js().locale(this.context.appSettings.momentLang);
    var locationTitle = this.context.appAccountLocationTitle;
    date_now.utcOffset(current_tz.utcOffset());
    date_now = date_now.format('LL');
    var selectEmployeeCallBack = this.props.selectEmployeeCallBack;
    var eventsToday = [];
    var eventsYesterday = [];
    var midnight_ts = moment().subtract(1,'days').endOf('day').format('X');
    for (var i = 0; i < this.props.events.length; i++) {
      var event = this.props.events[i];
      var event_ts = moment(event.event_datetime.iso_8601, moment.ISO_8601).format('X');
      var component = <Event clock_format={this.context.appSettings.clock_format} key={event.id} data={event} onSelectEmployee={selectEmployeeCallBack}/>;
      if (midnight_ts < event_ts) {
        eventsToday.push(component);
      } else {
        eventsYesterday.push(component);
      }
    };

    var eventsTodayDiv = function () {
      let label = gettext("Dagens aktivitet");
      let label2 = gettext("Ingen aktiviteter i dag");
      if (!!locationTitle) {
        label = locationTitle + ": " + label
      }
      if (+eventsToday.length) {
        return (
          <div>
            <div className="day-headline">{label}</div>
            {eventsToday}
          </div>
        );
      } else {
        return (
          <div>
            <div className="day-headline">{label}</div>
            <span className="empty no-activities">{label2}</span>
          </div>
        );
      }
    };

    var eventsYesterDayDiv = function(){
      let label = gettext("Gårsdagens aktivitet");
      if (+eventsYesterday.length) {
        return (
          <div>
            <div className="day-headline margin-top-40">{label}</div>
            {eventsYesterday}
          </div>
        );
      } else {
        return ("");
      }
    };
    var today = eventsTodayDiv(),
        yesterday = eventsYesterDayDiv(),
        time_format = (this.context.appSettings.clock_format == 12) ? 'h:mm A' : 'HH:mm';

    return (
        <div className="events">
        <span className="hide-activity btn" onTouchTap={this.hideActivity}>
          <span className="btnText">{gettext("Skjul aktiviteter")}</span>
        </span>
        
          {today}
          {yesterday}
        </div>
    );
  }
});


var Event = createReactClass({

  handleClick: function () {
    this.props.onSelectEmployee(this.props.data.worklog.owner);
  },

  renderEvent: function(eventText){
    let event_dt = this.props.data.event_datetime.time;
    return(
      <div className="itemWrap" onTouchTap={this.handleClick}>
        <span className={this.props.data.event_type}></span>
        <div className="textWrap">
          <span className="name">{this.props.data.worklog.owner.full_name}</span>
          <div className="infoWrap">
            <p className="activity-desc">
              {eventText}
            </p>
          </div>
        </div>
        <div className="timeWrap">
          <p className="timeStamp">{event_dt}</p>
          <span className="clockIcon"></span>
        </div>
      </div>
    )
  },
  render: function() {
    switch (this.props.data.event_type) {
      case "clock_in":
          return this.renderEvent(gettext("Stemplede ind"));
      case "break_start":
          return this.renderEvent(gettext("Gik til pause"));
      case "break_done":
          return this.renderEvent(gettext("Kom tilbage fra pause"));
      case "clock_out":
          return this.renderEvent(gettext("Stemplede ud"));
    }
  }
});


var TerminalLogin = createReactClass({
  getInitialState: function() {
    return {errMsg: ""};
  },
  submitPin: function(pin){
    var apiDispatchCallback = function(jqXHR) {
      if (jqXHR.statusCode === 200 && jqXHR.body.token) {
        var token = jqXHR.body.token;
        this.props.onTokenReceived(token);
      }
      else if (jqXHR === 400) {
        this.setState({errMsg: jqXHR.body.errMsg ? jqXHR.body.errMsg : gettext("Der skete en fejl. Prøv venligst igen.")});
      }
      else if (jqXHR === 401) {
        this.setState({errMsg: jqXHR.body.errMsg ? jqXHR.body.errMsg : gettext("Der skete en fejl. Prøv venligst igen.")});
      }
      else {
        this.setState({errMsg: jqXHR.body.errMsg ? jqXHR.body.errMsg : gettext("Der skete en fejl. Prøv venligst igen.")});
      }
    }.bind(this);

    // Submit call to API. will call callback when done.
    var data = {account_uuid: this.props.accountUUID, location_uuid: this.props.locationUUID, pincode: pin.join("")};
    Api.Dispatcher({endPoint: 'auth/clock_account_token/', method: 'post', data: data, datatype: 'json'}, apiDispatchCallback)

  },

  componentDidUpdate: function(prevProps, prevState){
    if (prevState.errMsg) {
      this.setState({errMsg: ""});
    }
  },

  render: function() {
    if (this.state.errMsg) {
      alert(this.state.errMsg);
    }

    let buttonLink = 'https://www.smartplan.dk/blog/sadan-bruger-du-smartplans-stempelur/'
    if (this.props.languageId == 'en-GB' || this.props.languageId == 'en-US') buttonLink = 'https://smartplan-knowledge-base.helpscoutdocs.com/article/258-punch-in-and-out-through-a-computer-or-tablet'
    if (this.props.languageId == 'de') buttonLink = 'https://smartplan-handbuch.helpscoutdocs.com/article/328-stempeln-via-pc-tablet'

    return (
      <div className="scrollable height-100">
        <div className="row height-100">
          <div className="col-sm-4 off-sm-4 pincodeWrap height-100 scrollable">
            <img src="/static/img/logo.svg" className="logo"/>
            <div className="titel introScreen hidden">{gettext("Stempelur")}</div>
            <KeyPad.Main submitOnChars="6" onSubmit={this.submitPin}/>
            <a className="textLink" href={buttonLink} target='_blank'>{gettext("Hvor finder jeg denne kode?")}</a>
          </div>
        </div>
      </div>
    );
  }
});


var ToolBar = createReactClass({
  contextTypes: {
    // Get context from App component. Timezone for the account
    appSettings: PropTypes.object,
    appTerminalLogOut: PropTypes.func,
    appAccountLocationTitle: PropTypes.string
  },
  keyPressListener: function(e){
    // keyPressListener has to be named... otherwise the listener can't be removed later on.
    this.handleKeyPress(e.keyCode);
  },
  componentDidMount: function(){
    // add eventlistener for keypresses
    var dom_node = ReactDOM.findDOMNode(this);
    dom_node.offsetParent.addEventListener('keydown', this.keyPressListener);
  },
  componentWillUnmount: function () {
    // remove eventlistener for keypresses!
    var dom_node = ReactDOM.findDOMNode(this);
    dom_node.offsetParent.removeEventListener('keydown', this.keyPressListener ,false);
  },
  toggleLogoutKeypad: function () {
    $(".punchclock-header .keypad-container").toggleClass("shown")
  },
  handleKeyPress(k) {
    var value = String.fromCharCode(k);
    if (k === 27){
      // Deactivate search on Escape press.
      var filterStatus = $(".searchFilter").hasClass("filterOn");
      if(filterStatus){
        this.showFilter();
      }
    }
  },
  filterTrigger() {
    // sending the new filter value to the parent component
    this.props.filterUpdate(this.refs.filterInput.value);
  },

  showActivity(){
    $(".activity-wrap").addClass("open");
  },
  // showFilter(){
  //   $(".searchFilter").toggleClass("filterOn");
  //   $(".search-icon").toggleClass("searchOff");
  //   $(".terminalHeader").toggle();

  //   var filterStatus = $(".searchFilter").hasClass("filterOn");

  //   if(filterStatus){
  //     $(".searchFilter").focus();
  //   } else {
  //     this.refs.filterInput.value = "";
  //     this.props.filterUpdate("");
  //   }
  // },

  submitPin(pin) {
    const data = {account_uuid: this.props.accountUUID, location_uuid: this.props.locationUUID, pincode: pin.join("")};
    Api.Dispatcher({endPoint: 'auth/clock_account_token/', method: 'post', data: data, datatype: 'json'}, (response) => {
      if (response.statusCode == 200) {
        this.context.appTerminalLogOut()
      } else alert(response.body.errMsg)
    })
  },

  render() {
    var current_tz = moment().tz(this.context.appSettings.tz);
    var date_now = moment_js().locale(this.context.appSettings.momentLang);
    var locationTitle = this.context.appAccountLocationTitle;
    date_now.utcOffset(current_tz.utcOffset());
    date_now = date_now.format('LL');
    var selectEmployeeCallBack = this.props.selectEmployeeCallBack;
    var eventsToday = [];
    var eventsYesterday = [];
    var midnight_ts = moment().subtract(1, 'days').endOf('day').format('X');
    var time_format = (this.context.appSettings.clock_format == 12) ? 'h:mm A' : 'HH:mm';
    return (
      
      <div className="col-xs-12 filterWrap punchclock-header">
        <div className="logo-header"></div>
        <form>
          {/* <div className="terminalHeader">
            <span className="hidden">{gettext("Klik på dig selv for at stemple ind eller ud")}</span>
          </div> */}
          <div className="searchWrap">
            <span className="search-icon" onTouchTap={this.showFilter}></span>
            <input
              type='text'
              ref='filterInput'
              placeholder={gettext('Søg i medarbejdere')}
              /* binding the input value to state */
              value={this.props.filterVal}
              onChange={this.filterTrigger}
              className="searchFilter"
            />
          </div>
          <span className="show-activity" onClick={this.showActivity}></span>
        </form>
        <div className="clockCon">
          <div className="clockTimer">
            <Clock tz={this.context.appSettings.tz} tFormat={time_format} locale={this.context.appSettings.momentLang} />
            <div className="date">{date_now}</div>
          </div>
          <span className="off-icon" onClick={this.toggleLogoutKeypad}></span>
        </div>
        <KeyPad.Main submitOnChars="6" onSubmit={this.submitPin} showHeader={true} />
      </div>
    )
  }
});

var EmployeeListContainer = createReactClass({

  contextTypes: {
      // Get context from App component. Timezone for the account
      appAccountToken:  PropTypes.string,
      appAccountLocationUUID: PropTypes.string,
      appRaiseInvalidAccountToken: PropTypes.func
  },
  
  getInitialState: function() {
    var employees = [];
    if (localStorage.employeeListContainerState) {
      var cachedState = JSON.parse(localStorage.employeeListContainerState);
      if (!!cachedState && cachedState.hasOwnProperty('employees')) {
        employees = cachedState.employees;
        localStorage.employeeListContainerState = null;
      }
    }
    return {employees: employees, intervalId: null};
  },
  loadDataFromServer: function () {
      // Construct endpoint
      var endPoint = 'punchclock/dashboard/employees/';
      if (!!this.context.appAccountLocationUUID) {
        endPoint = 'punchclock/location/' + this.context.appAccountLocationUUID + '/dashboard/employees/';
      }
    // TODO: catch errors!
    var apiDispatchCallback = function(jqXHR) {
      if (jqXHR.statusCode === 200)  {
        var employees = jqXHR.body.employees;
        this.setState({employees: employees});

      }
      else if ([401].indexOf(jqXHR.statusCode) >= 0) {
        this.context.appRaiseInvalidAccountToken({msg: gettext("Ugyldig pinkode, prøv venligst igen.")});
      }
      else {
        var errMsg = jqXHR.body.detail ? jqXHR.body.detail : gettext("Der skete en fejl. Prøv venligst igen.");
        //alert(errMsg);  Don't raise error here...
      }
    }.bind(this);
    Api.Dispatcher({endPoint: endPoint, method: 'get', headers: {'Authorization': "token " + this.context.appAccountToken}}, apiDispatchCallback);

  },
  componentDidMount: function(){
    this.loadDataFromServer();
    var intervalId = setInterval(this.loadDataFromServer, 600000);
    this.setState({intervalId: intervalId});
  },
  componentWillUnmount: function() {
    // clearInterval when unmounted... no more need for fetching shifts.
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  },
  componentDidUpdate: function() {
    localStorage.employeeListContainerState = JSON.stringify(this.state);
  },

  render() {
      return <EmployeeList 
              employees={this.state.employees} 
              selectEmployeeCallBack={this.props.selectEmployeeCallBack} 
              filterText={this.props.filterText}
              />;
    }

});


var EmployeeList = createReactClass({
  
  getInitialState: function() {
    return {employees: []};
  },

  renderActiveEmployees: function () {
    var activeEmployees = [];
    var selectEmployeeCallBack = this.props.selectEmployeeCallBack;
    var input = this.props.filterText.toLowerCase(); // use props.filterText

    this.props.employees.forEach(employee => {
      var fullName = employee.first_name + " " + employee.last_name;
      if (fullName.toLowerCase().includes(input) && employee.active) {
        var component = <Employee key={employee.id} data={employee} onSelectEmployee={selectEmployeeCallBack} />;
        activeEmployees.push(component);
      }
    });

    if (activeEmployees.length > 0) {
      return (
        <div className="employeeListCon">
          <div className="listTitle">{gettext("Dagens medarbejdere:")}</div>
          {activeEmployees}
        </div>
      );
    }
    return;
  },

  renderAllEmployees: function () {
    var allEmployees = [];
    var selectEmployeeCallBack = this.props.selectEmployeeCallBack;
    var input = this.props.filterText.toLowerCase(); // Use props.filterText instead of state

    for (var i = 0; i < this.props.employees.length; i++) {
      var employee = this.props.employees[i];
      var fullName = employee.first_name + " " + employee.last_name;
      if (fullName.toLowerCase().includes(input)) {
        var component = <Employee key={employee.id} data={employee} onSelectEmployee={selectEmployeeCallBack} />;
        allEmployees.push(component);
      }
    }

    if (allEmployees.length > 0) {
      return (
        <div className="employeeListCon nonPlanned">
          <div className="listTitle">{gettext("Alle medarbejdere:")}</div>
          {allEmployees}
        </div>
      );
    }
    return;
  },

  render: function() {
    // TODO: implement empty state for activeEmployees
    var activeEmployees = this.renderActiveEmployees();
    var allEmployees = this.renderAllEmployees();
    return (
      <div id="employeeList" className="employeeList col-xs-12 col-sm-7 height-100">
       <div className="scrollArea">
       {activeEmployees}
       {allEmployees}
      </div>
    </div>
    );
  }
});


var Employee = createReactClass({
  getInitialState: function(){
    // this.props er initial data sendt med fra 'parent'...
    return {data: this.props.data};
  },

  handleClick: function () {
    this.props.onSelectEmployee(this.state.data);
  },

  render: function() {
    // check status and render accordingly
    let phone = "";
    if (this.state.data && this.state.data.user && this.state.data.user.phone) {
      phone = this.state.data.user.phone;
    }

    return(
      <div className="employee" onTouchTap={this.handleClick}>
        <Avatar member={this.state.data} size={'medium'}/>
        <div className="personInfo">
          <h2>{this.state.data.first_name} {this.state.data.last_name}</h2>
          <span className="phoneNo">{phone}</span>
        </div>
      </div>
    )
  }
});


var Terminal = createReactClass({

  getInitialState: function() {
    var confirmation = this.props.confirmation || null;
    
    return {
      confirmation: confirmation,
      filterText: '' // ensure this is a string
    };
  },
  handleEmployeeSelection: function(value) {
    this.props.onEmployeeSelected(value);
  },
  closeSuccessBox: function() {
    this.setState({confirmation: null});
  },
  stateUpdate(filterValue) {
    // Update the state with the new filter value
    this.setState({ filterText: filterValue });
  },

  renderSuccessBox: function() {
    if (this.state.confirmation) {
        return <SuccessBox seconds='5' label={this.state.confirmation} closeBoxCallback={this.closeSuccessBox}/>;
    }
    return;
  },

  render: function() {
    var successBox = this.renderSuccessBox()
    return (
      <div className="row height-100">
        <div>
          {successBox}
        </div>
        <ToolBar
          // passing down state into Filter component via props
          locationUUID={this.props.locationUUID}
          accountUUID={this.props.accountUUID}
          filterVal={this.state.filterText}
          filterUpdate={this.stateUpdate}
        />
        <EmployeeListContainer 
          selectEmployeeCallBack={this.handleEmployeeSelection}
          filterText={this.state.filterText}
          />
        <EventListContainer selectEmployeeCallBack={this.handleEmployeeSelection}/>
      </div>
    );
  }
});


module.exports = {
  Terminal: Terminal,
  Login: TerminalLogin
};
