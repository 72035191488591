/**
* Created by petermoeller on 28/10/15.
*/
"use strict"
var React = require('react');
var moment = require('moment');
var createReactClass = require('create-react-class');
var PropTypes = require('prop-types');
/*
Mounted by a parent.

set to a timeout and timeoutCallback

props:
  timeout: int
  closeBoxCallback: int
  label: string


 */


var SuccessBox = createReactClass({
  getInitialState: function () {
    var seconds_left = this.props.seconds || 5;
    var ts_now = moment().format('X');
    var end_timestamp = +ts_now + +seconds_left;
    var label = this.props.label || "en besked";
    return {end_timestamp: end_timestamp, seconds_left: seconds_left, label: label};
  },
  refreshTimer: function() {
    var now_timestamp = moment().format('X');
    var seconds_left = this.state.end_timestamp - +now_timestamp;
    if (seconds_left < 1) {
      this.handleClose();
    } else {
      this.setState({seconds_left: seconds_left});
    }
  },

  handleClose: function() {
    // when the 'close' button is clicked.
    this.props.closeBoxCallback();
  },

  componentDidMount: function() {
    // load shifts from server and setInterval for doing this later on.
    // TODO: move setInterval stuff into a combined websockets/interval-set-clear method.
    var intervalId = setInterval(this.refreshTimer, 1000);
    this.setState({intervalId: intervalId});
  },
  componentWillUnmount: function() {
    // clearInterval when unmounted... no more need for fetching shifts.
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  },
  render: function () {
    var seconds_left = this.state.seconds_left;
    return (
      <div className="successBox">
        <div className="successWrap">
          <div className="successTitel">{this.state.label}</div>
        </div>
        <div className="closeBtn btn" onTouchTap={this.handleClose}>{gettext("Luk besked")} ({this.state.seconds_left})</div>
      </div>
    );
  }
});


module.exports = SuccessBox;